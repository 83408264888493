<template>
    <dashboardPagesLayout>
        <div class="p-inside-manage">
            <div class="page_title">Promotions</div>
            <div class="wrap_manage_table under_construction">
                <span>In development</span>
            </div>
        </div>
    </dashboardPagesLayout>
</template>

<script>
import dashboardPagesLayout from "@/layouts/dashboardPagesLayout";
export default {
    name: "promotions",
    components: {
        dashboardPagesLayout
    },
}
</script>

<style scoped>
@media screen and (max-width: 480px) {
    h1 {
        font-size: 40px !important;
        text-align: center;
        margin-bottom: 15px !important;

    }
}
</style>